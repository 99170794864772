<script setup lang="ts">
import type { Station } from '~/types/stations'
import IconFeatured from '~/components/icons/IconFeatured.vue'
import IconSponsored from '~/components/icons/IconSponsored.vue'

const identifier = useRoute().params.id as string

const nuxtApp = useNuxtApp()
const { data: station } = await useAsyncData<Station>(
  `station-${identifier}`,
  () => $fetch(`/api/stations/${identifier}`),
  {
    getCachedData(key) {
      return nuxtApp.payload.data[key] || nuxtApp.static.data[key]
    },
  }
)

if (!station.value) {
  throw createError({
    statusCode: 404,
    statusMessage: 'Page Not Found',
  })
}

if (station.value.slug !== identifier) {
  navigateTo(station.value.route, { redirectCode: 301 })
}

useHead({
  link: [
    {
      rel: 'icon',
      type: 'image/x-icon',
      href: '/favicon.ico',
    },
  ],
})

useSeoMeta({
  title: `Listen to ${station.value.title}`,
  ogTitle: station.value.title,
  description:
    `Tune in to ${station.value.title} and enjoy the best of ` +
    (station.value.languages.length ? `${station.value.languages.join(', ')} ` : '') +
    `${station.value.genre} radio. Stream live broadcasts, catch up on past episodes, and stay updated with the latest news and announcements.`,
  ogDescription:
    station.value.metaDescription ||
    `Tune in to ${station.value.title} and enjoy the best of ` +
      (station.value.languages.length ? `${station.value.languages.join(', ')} ` : '') +
      `${station.value.genre} radio. Stream live broadcasts, catch up on past episodes, and stay updated with the latest news and announcements.`,
  ogImage: station.value.metaLogo,
  twitterCard: 'summary_large_image',
  twitterTitle: station.value.title,
  twitterDescription:
    station.value.metaDescription ||
    `Tune in to ${station.value.title} and enjoy the best of ` +
      (station.value.languages.length ? `${station.value.languages.join(', ')} ` : '') +
      `${station.value.genre} radio. Stream live broadcasts, catch up on past episodes, and stay updated with the latest news and announcements.`,
  twitterSite: '@zenodotfm',
  twitterImage: station.value.metaLogo,
})
</script>

<template>
  <div v-if="station" class="space-y-12">
    <CoverImage :src="station.cover" :alt="station.title" />

    <div class="flex md:flex-row flex-col md:space-x-8 space-x-0 md:space-y-0 space-y-8">
      <div class="space-y-8 md:pl-8 pl-0 -mt-20 flex-none">
        <div class="text-center">
          <StationLogo :src="station.logo" :alt="station.title" />
        </div>

        <div class="flex justify-center">
          <StationPlaybackButton :station="station" />
        </div>
      </div>

      <section class="space-y-5 flex-1">
        <h1 class="md:text-2xl text-lg font-semibold md:text-left text-center flex items-center gap-2">
          {{ station.title }}

          <span
            v-if="station.sponsored"
            title="Sponsored"
            class="p-1 bg-zeno-yellow rounded-full items-center gap-1.5 inline-flex"
          >
            <IconSponsored />
            <span class="text-xs text-white pr-2.5">Sponsored</span>
          </span>

          <span v-else-if="station.featured" title="Featured" class="inline-flex">
            <IconFeatured />
          </span>
        </h1>

        <StationTags :station="station" />

        <LazyStationAdRectangle />

        <LazyStationAdPrimis v-if="!station.featured && !station.adsDisabled" />

        <!-- eslint-disable vue/no-v-html -->
        <div v-if="station.description" class="text-zeno-gray" v-html="station.description" />
        <!--eslint-enable-->

        <StationSocialLinks :station-id="station.id" />
      </section>
    </div>

    <StationCards :station-id="station.id" />

    <StationPodcasts :station-id="station.id" />

    <StationRelated v-if="!station.featured && !station.adsDisabled" :station="station" />
  </div>
</template>

<style scoped></style>
