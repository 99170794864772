<script setup lang="ts">
import type { Station, StationListItem } from '~/types/stations'
import type { StationsSearchResponse } from '~/server/api/stations/index.types'

const { station } = defineProps<{
  station: Station
}>()

const nuxtApp = useNuxtApp()

const { data: stations, status } = useAsyncData<StationListItem[]>(
  `station-related-${station.id}`,
  async () => {
    const [genreCountryStations, countryStations] = await Promise.all([
      $fetch<StationsSearchResponse>('/api/stations/', {
        params: {
          country: station.country,
          genre: station.genre,
          limit: 13,
        },
      }),
      $fetch<StationsSearchResponse>('/api/stations/', {
        params: {
          genre: station.genre,
          limit: 13,
        },
      }),
    ])

    return genreCountryStations.data
      .concat(countryStations.data)
      .filter((item) => item.id !== station.id)
      .slice(0, 12)
  },
  {
    getCachedData(key) {
      return nuxtApp.payload.data[key] || nuxtApp.static.data[key]
    },
  }
)

const params = computed(() => {
  return {
    country: station?.country,
    genre: station.genre,
  }
})

const stationList = computed(() => {
  if (!stations.value) return []

  return stations.value
})
</script>

<template>
  <div>
    <MediaListRow
      :items="stationList"
      title="Similar Stations"
      type="stations"
      :route="{ name: 'radio', query: params }"
      :pending="status === 'pending'"
    />
  </div>
</template>

<style scoped></style>
